
*Note: the import process is done entirely on your device to preserve your privacy.*

# Google Keep

1. Go to [Google Takout](https://takeout.google.com/)
2. Select only Keep's data for export
   *it'll be ready for download in a few minutes*
3. [Click here](#keep) to import notes from the zip file


# Apple Notes

*Coming soon ...*

# Standard Notes

1. Open Standard Notes app or [website](https://app.standardnotes.com/)
2. Select your notes and press Export to download the zip file
3. [Click here](#standard-notes) to import notes from the zip file

# CLI and Public APIs

Please check out the [GitHub page](https://github.com/computing-den/unforget?tab=readme-ov-file#public-apis---write-your-own-client) for the instructions.
